import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/Publications.css';
// import phImage from  '../static/images/placeholder.jpg';

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error); // Propagate the error
    }
);

const fetchPublications = async () => {
    try {
        const response = await axios.get('publications/jsons/journal_papers.json');
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching publications data. HTTP status:', error.response?.status);
        return [];
    }
};

const Publications = () => {
    const [ setPublications] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const publicationsData = await fetchPublications();
            setPublications(publicationsData);
        };

        fetchData();
    }, []);

      return (
          <div>

                  <h1>Publications in International Journals</h1>
              <div className="publication">
                  <a href="/Journals">
                      <fieldset>
                          <legend>Journal paper</legend>
                          International Journal Papers
                      </fieldset>
                  </a>
              </div>
                  <div className="publication">
                  <a href="/Conferences">
                      <fieldset>
                          <legend>Conference paper</legend>
                          International Conference Papers
                      </fieldset>
                  </a>
                  </div>
              <div className="publication">
                  <a href="/Books">
                      <fieldset>
                          <legend>Books</legend>
                          Published Books
                      </fieldset>
                  </a>
              </div>
          </div>

    );
};

export default Publications;
