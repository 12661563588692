// import React, { useEffect, useState } from 'react';

const Education = () => {
    return (
        <div  className="body2">
                <h1>Education</h1>

                <h4>Ph.D. in Applied Mathematics</h4>
                <h5>Sep. 2005</h5>
                <h5>Science and Research Branch, Azad University, Tehran, Iran</h5>
                <h5>The Universal Approximation of Fuzzy Functions</h5>
                {/*<h5>Supervisor: Prof. Saeid Abbasbandy</h5>*/}
                <br></br>


                <h4>Master of Applied Mathematics</h4>
                <h5>Sep. 2000</h5>
                <h5>Sharif University of Technology, Tehran, Iran</h5>
                <h5>Approximation of Multivariate Functions by Optimization</h5>
                {/*<h5>Supervisor: Prof. Bahman Mehri</h5>*/}
                <br></br>

                <h4>Bachelor of Applied Mathematics</h4>
                <h5>Sep. 1998</h5>
                <h5>University of Guilan, Rasht, Iran</h5>
        </div>



        // <div className="bio" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default Education;

