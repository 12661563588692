import '../../../styles/FlashCard.css';

const FlashCardBook = ({title, authors, publication_year, publisher, edition,language}) => {
    return (
        <div className="flash-card" >
            <div className="front">
                <h3>{title}</h3>
                <br></br>
                <p>{authors}</p>
                <p>{publication_year}</p>
                <p>{publisher}, {edition}.</p>
                <br></br>
                <p>{`Language: ${language}`}</p>
            </div>
            <div className="back">
                <p>{authors}</p>
            </div>
        </div>
    );
};

export default FlashCardBook;