// src/components/Footer.js
import React from 'react';
import '../../../styles/Footer.css';

const Footer = () => {
    return (
        <footer>
            &copy; 2024 Majid Amirfakhrian
        </footer>
    );
};

export default Footer;
