import React from "react";

const People = () => {

      return (
          <div>

                  <h1>People</h1>
              <div className="publication">
                  <a href="/collaboration">
                      <fieldset>
                          <legend>Collaboration</legend>
                          Project Collaboration People
                      </fieldset>
                  </a>
              </div>
                  <div className="publication">
                  <a href="/phdstudents">
                      <fieldset>
                          <legend>PhD</legend>
                          Graduated PhD Students
                      </fieldset>
                  </a>
                  </div>
              <div className="publication">
                  <a href="/masterstudents">
                      <fieldset>
                          <legend>Master</legend>
                          Graduated Master Students
                      </fieldset>
                  </a>
              </div>
          </div>

    );
};

export default People;
