import '../../../styles/FlashCard.css';

const FlashCardJournal = ({title, authors, year, journal, volume, pages}) => {
    return (
        <div className="flash-card" >
            <div className="front">
                <h3>{title}</h3>
                <br></br>
                <p>{authors}</p>
                <p>{year}</p>
                <p>{journal}, {volume}, {pages}.</p>
            </div>
            <div className="back">
                <p>{authors}</p>
            </div>
        </div>
    );
};

export default FlashCardJournal;