// import React, { useEffect, useState } from 'react';

import React from "react";

const Positions = () => {
      return (
                <div>

                        <h1>Positions</h1>
                        <div className="publication">
                                <a href="/academic">
                                        <fieldset>
                                                <legend>Academic</legend>
                                                Academic Positions
                                        </fieldset>
                                </a>
                        </div>
                        <div className="publication">
                                <a href="/scientific">
                                        <fieldset>
                                                <legend>Scientific</legend>
                                                Scientific Positions
                                        </fieldset>
                                </a>
                        </div>
                        <div className="publication">
                                <a href="/administrative">
                                        <fieldset>
                                                <legend>Administrative</legend>
                                                Administrative Positions
                                        </fieldset>
                                </a>
                        </div>
                </div>

    );
};

export default Positions;

