// src/components/NotFound.js
import React from 'react';
import '../../../styles/NotFound.css'

const NotFound = () => {
    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h2>404 - Page Not Found</h2>
                <p>Sorry, the page you are looking for does not exist.</p>
            </div>
        </div>
    );
};

export default NotFound;
