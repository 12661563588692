import '../../../styles/FlashCard.css';

const FlashCardConference = ({title, authors, date, conference, location, pages}) => {
    return (
        <div className="flash-card" >
            <div className="front">
                <h3>{title}</h3>
                <br></br>
                <p>{authors}</p>
                <p>{date}</p>
                <p>{conference}, {location}, {pages}.</p>
            </div>
            <div className="back">
                <p>{authors}</p>
            </div>
        </div>
    );
};

export default FlashCardConference;