import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProfessorCard from '../Elements/people/ProfCard';
import '../../styles/People.css';

const Collaborations = () => {
    const [professors, setProfessors] = useState([]);

    useEffect(() => {
        const fetchProfessors = async () => {
            try {
                const response = await axios.get('/people/jsons/profs.json');
                setProfessors(response.data);
            } catch (error) {
                console.error('Error fetching professors data:', error);
            }
        };

        fetchProfessors();
    }, []);

    const handleImageClick = (index) => {
        // Handle the image click, e.g., navigate to the link
        const professor = professors[index];
        window.location.href = professor.url_link;
    };

    return (
        <div  className="body3">
            <h1>Collaborations with Professors</h1>

            <div className="professors-container">
                {professors.map((professor, index) => (
                    <ProfessorCard
                        key={index}
                        {...professor}
                        onClick={() => handleImageClick(index)}
                    />
                ))}
            </div>

            <div>
                <a href="https://www.flaticon.com/free-icon/bussiness-man_7318719?term=man&page=3&position=75&origin=search&related_id=7318719">
                    <small> <font color="red">Man icon </font>created by Freepik - Flaticon.</small>
                </a>
                <br></br>
                <a
                    href="https://www.flaticon.com/free-icon/businesswoman_1896516?term=woman&page=2&position=13&origin=search&related_id=1896516">
                    <small> <font color="red">Woman icon </font>created by Freepik - Flaticon.</small>
                </a>
            </div>
        </div>
    )
        ;
};

export default Collaborations;