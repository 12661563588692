// import React, { useEffect, useState } from 'react';

const Bio = () => {
    return (
        <div  className="body2">
            <h1>Biography</h1>


            Born and raised in Tehran, Iran. Majid Amirfakhrian went to the&nbsp;University of Guilan, Rasht, Iran for his post-secondary education. He&nbsp;received his Master of Science in Applied Mathematics from the Sharif&nbsp;University of Technology and completed his Ph.D. through the Azad&nbsp;University, Science and Research branch.&nbsp;

            He joined the Azad University, Central Tehran Branch (<a>IAUCTB</a>) in&nbsp;2002. He was promoted to a full professorship of Applied Mathematics at&nbsp;IAUCTB in 2015.&nbsp;He is currently an individual researcher in Calgary,&nbsp;Canada.

            Majid Amirfakhrian is a knowledgeable and gifted researcher with an&nbsp;exceptional publication record who has garnered international recognition
            for the quality of his work. He is described as an excellent colleague who&nbsp;is very popular with students. For his deep commitment to the Department
            of Mathematics, Statistics, and Computer Sciences through his stellar&nbsp;research career, teaching, and administrative service.
            He has some administrative experience in IAUCTB and some executive&nbsp;experience. He is the founder of the Applied Research Club (ARC ) in
            IAUCTB.
            His main research interests lie in Numerical Analysis and Approximation.&nbsp;Some of his specific interests are solving the problems of Fuzzy
            Approximation, Data Sciences, Multivariate Approximation, Image&nbsp;Processing, and Numerical Partial Differential Equations. He uses
            approximation techniques in some applied problems, but at the same time,&nbsp;he works on the theoretical aspects of mathematical problems.
            He has side interests in Optimization and Statistics. He uses&nbsp;computational methods in his research.

            Majid has supervised more than fifteen undergraduate research projects&nbsp;and more than sixty Master's research projects. He also supervised ten Ph.D.&nbsp;research projects. All Ph.D. projects generated papers published in or&nbsp;submitted to refereed journals.&nbsp;He has been also the author or co-author of many books in mathematics.

            Interests
            <ul>
                <li>Data Sciences</li>
                <li>Image Processing</li>
                <li>Fuzzy Logic</li>
                <li>Approximation Theory</li>
                <li>Numerical Weather Prediction</li>
                <li>Numerical Methods for PDEs</li>
                <li>Fuzzy Function Approximation</li>
                <li>Solving Fuzzy Equations Numerically</li>
                <li>Numerical Linear Algebra</li>
                <li>Numerical Methods for Integral Equations</li>
                <li>Multivariate Functions Approximation</li>
            </ul>
        </div>



        // <div className="bio" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default Bio;

