import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'; // Import BrowserRouter
import './styles/Body.css';

import Home from './components/Home';
import Contact from './components/Contact';
import About from './components/About/About';
import Publications from './components/Publications/Publications'
import JournalPapers from "./components/Publications/JournalPapers";
import Header from './components/Elements/layout/Header';
import Footer from './components/Elements/layout/Footer';
import Menu from './components/Elements/layout/Menu';
import NotFound from './components/Elements/layout/NotFound';
import Bio from './components/About/Bio';
import Conferences from "./components/Publications/Conferences";
import Books from "./components/Publications/Books";
import Education from "./components/About/Education"
import Awards from "./components/About/Awards";
import Positions from "./components/Positions/Positions";
import AcademicPositions from "./components/Positions/AcademicPositions";
import JournalPositions from "./components/Positions/JournalPositions";
import ExecPositions from "./components/Positions/ExecPositions";
import Teaching from "./components/Skills/Teaching";
import OtherSkills from "./components/Skills/OtherSkills";
import Skills from "./components/Skills/Skills";
import Collaborations from "./components/People/Collaboration";
import PhdStudents from "./components/People/PhdStudents";
import Mastertudents from "./components/People/MasterStudents";
import People from "./components/People/People";
import ScrollToTopButton from "./components/Elements/layout/ScrollToTopButton";
import NightModeButton from "./components/Elements/layout/NightModeButton";
// import VisitCounter from "./components/Elements/layout/VisitCounter";


function App() {
    return (
        <Router>
            <Header/>
            <Menu/>
                <div className="body">
                    <div className="body-container">
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/journals" element={<JournalPapers/>}/>
                            <Route path="/publications" element={<Publications/>}/>
                            <Route path="/conferences" element={<Conferences/>}/>
                            <Route path="/books" element={<Books/>}/>
                            <Route path="/bio" element={<Bio/>}/>
                            <Route path="/education" element={<Education/>}/>
                            <Route path="/awards" element={<Awards/>}/>
                            <Route path="/positions" element={<Positions/>}/>
                            <Route path="/academic" element={<AcademicPositions/>}/>
                            <Route path="/executive" element={<ExecPositions/>}/>
                            <Route path="/journalposition" element={<JournalPositions/>}/>
                            <Route path="/skills" element={<Skills/>}/>
                            <Route path="/teaching" element={<Teaching/>}/>
                            <Route path="/otherskills" element={<OtherSkills/>}/>
                            <Route path="/people" element={<People/>}/>
                            <Route path="/collaboration" element={<Collaborations/>}/>
                            <Route path="/phdstudents" element={<PhdStudents/>}/>
                            <Route path="/masterstudents" element={<Mastertudents/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>
                        <ScrollToTopButton/>
                        <NightModeButton/>
                        {/*<VisitCounter />*/}
                </div>
            </div>
            <Footer/>
            {/*</div>*/}
        </Router>
    );
}

export default App;
