import React from 'react';


const PhDStudCard = ({ student_name, thesis_title, completion_year, image }) => {

    return (
        <div className="student-card">
            <img
                style={{
                    width: '230px',
                    height: '230px'
                }}
                src={image} alt={student_name}/>
            {/*<img src={image} alt={student_name}/>*/}
            <div className="card-details">
                <h3>{student_name}</h3>
                <br></br>
                <p>{`Thesis:`}</p>
                <p>{thesis_title}</p>
                <p>{`Year of Completion: ${completion_year}`}</p>
            </div>
        </div>
    );
};

export default PhDStudCard;
