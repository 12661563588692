import React, { useState } from 'react';
import '../../../styles/NightModeButton.css'


const NightModeButton = () => {
    const [isNightMode, setIsNightMode] = useState(false);

    const toggleNightMode = () => {
        const newNightMode = !isNightMode;
        setIsNightMode(newNightMode);
        document.body.classList.toggle('night-mode', newNightMode);
    };

    return (
        <button className="night-mode-button" onClick={toggleNightMode}>
            <i className={`fas ${isNightMode ? 'fa-sun' : 'fa-moon'}`} aria-hidden="true"></i>
            {/*{isNightMode ? 'Day Mode' : 'Night Mode'}*/}
            {/*{isNightMode ? 'Day Mode' : 'Night Mode'}*/}
        </button>
    );
};

export default NightModeButton;
