// import React, { useEffect, useState } from 'react';

const OtherSkills = () => {
    return (
        <div className="body2">
            <h1>Skills</h1>

            <ul>
                <li>Mathematical Software (MATLAB, Mathematica)</li>
                <li>Statistical Analysis and Predictive Modeling and R Programming</li>
                <li>Java and Python Programming (Algorithm Design)</li>
                <li>Database Management (MS SQL Server (SSMS, SSIS, SSDT, SSAS), Oracle 12c, MySQL, MongoDB)</li>
                <li>Statistical Techniques (Regression Analysis)</li>
                <li>Data Visualization (Power BI, Tableau, Dashboard, Wolfram Language)</li>
                <li>Data Analytics Streaming and CRISP-DM (KNIME, Orange)</li>
                <li>LaTeX</li>
            </ul>
            <ul>
                <li>Web Application Development</li>
                <li>Software Troubleshooting and Maintenance</li>
                <li>Collaboration and Teamwork</li>
                <li>Algorithm Design</li>
                <li>Problem Solving</li>
                <li>Teaching</li>
                <li>Research</li>
                <li>MS Office</li>
            </ul>
            <h1>Hobby</h1>
            <ul>
                <li>Game Designing using JavaScript</li>
            </ul>
        </div>

    );
};

export default OtherSkills;

