import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/Publications.css';
import FlashCardBook from "../Elements/publications/FlashCardBook";

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error); // Propagate the error
    }
);

const fetchPublications = async () => {
    try {
        const response = await axios.get('publications/jsons/my_books.json');
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching publications data. HTTP status:', error.response?.status);
        return [];
    }
};

const Publications = () => {
    const [publications, setPublications] = useState([]);
    const [clickedIndex, setClickedIndex] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const publicationsData = await fetchPublications();
            setPublications(publicationsData);
        };

        fetchData();
    }, []);

    const handleImageClick = (index) => {
        // Handle the image click, e.g., navigate to the link
        const publication = publications[index];
        window.location.href = publication.url_link;
    };

    return (
        <div  className="body2">
            <h1>Books</h1>
            {publications.map((publication, index) => (
                <div key={index} className="publication">
                    <fieldset>
                        <legend>Book</legend>
                        <table>
                            <tr>
                                <td rowSpan="3" colSpan="1">
                                    <div
                                        className={`shadowbox ${clickedIndex === index ? 'clicked' : ''}`}
                                        style={{
                                            backgroundImage: `url(${publication['image location'] || '/images/placeholer.jpg'})`,
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            width: '300px',
                                            height: '300px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            boxShadow: clickedIndex === index ? 'none' : '8px 8px 5px #444',
                                            transition: 'box-shadow 0.1s ease', // Add transition property
                                        }}
                                        onMouseDown={() => setClickedIndex(index)}
                                        onMouseUp={() => setClickedIndex(null)}
                                        onClick={() => handleImageClick(index)}
                                    >
                                        {!publication['image location'] && (
                                            <div className="placeholder">
                                                <span className="placeholder-text">No Image Available</span>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a className="publication-details" href={publication.url_link}>
                                        <div>
                                            <FlashCardBook key={index} {...publication} />
                                        </div>
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </fieldset>
                </div>
            ))}
        </div>
    );
};

export default Publications;
