import '../../styles/FlashCard2.css';

const FlashCardPosition = ({position, organization, year}) => {
    return (
        <div className="flash-card2" >
            <div className="front">
                <h2>{position}</h2>
                {/*<br></br>*/}
                <h6>{year}</h6>
                <h5>{organization}</h5>

            </div>
            <div className="back">
                <p>{position}</p>
            </div>
        </div>
    );
};

export default FlashCardPosition;