// import React, { useEffect, useState } from 'react';

const Awards = () => {
    return (
        <div  className="body2">
            <h1>Honor & Awards</h1>

            <div>
            <p><b>Best Scientific Book Author Award:</b> Best Scientific Book Author Award of Azad University, Central Tehran Branch</p>
            </div>
            <p><b>Membership:</b> Young Researcher and Elite Club, Azad University</p>
            <p> <b>Best Researcher Award Best Researcher Award of Azad University, Central Tehran Branch</b></p>
            <p> <b>Best Researcher Award Best Researcher Award of Azad University, Central Tehran Branch</b></p>
            {/*<p> <b>Youngest Full Professor of Islamic Azad University</b> I was awarded a Full Professor position when I had only 39 years old.</p>*/}
            <p> <b>Supervisor of IAUCTB Inventors’ Team, Geneva, Swiss:</b> Supervisor of Azad University inventors’ send-out to “International Exhibition of Inventions of Geneva”, Swiss.</p>
            <p> <b>Standing the First Place in IAU Ph.D. Entrance Exam in IRAN:</b> Azad University.</p>
            <p> <b>Standing the Second Place in MSc Entrance Exam in IRAN:</b> Standing the Second Place in the Master of Sciences Entrance Exam in IRAN</p>
            <p> <b>Scientific Olympiad of Iranian University Students:</b> One of the distinguished and awarded persons in the final level of the Scientific Olympiad of Iranian University Students</p>
            <p> <b>Awarded by Colleagues:</b> for the valuable activities as the Dean of the Department of Mathematics</p>
            <p> <b>Awarded by YREC:</b> for the valuable activities as the Awarded for the valuable activities as the Dean of the Young Researcher and Elite Club</p>
            <p> <b>6 Talks in Science Radio of Iran:</b> 3 talks on Numerical Analysis and 3 talks on Fuzzy Mathematics.</p><p> <b>Awarded for the valuable activities</b> Awarded for the valuable activities as the Dean of the Young Researchers and Elite Club</p>
            <p> <b>Awarded for the valuable activities:</b> Awarded for the valuable activities as the Dean of the Mathematics Department</p>

        </div>



        // <div className="bio" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default Awards;

