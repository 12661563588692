// src/components/Menu.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../../../styles/Menu.css';

const Menu = () => {
    return (
        <div className="menu-container">
            {/*<nav>*/}
            <ul className="menu-bar">
                <li><Link to="/">Home</Link></li>
                {/*<li><NavLink to="/" activeClassName="active-link">Home</NavLink></li>*/}
                <li><Link to="/about">About</Link>
                    <ul className="sub-menu">
                        <li><Link to="/Bio">Biography</Link></li>
                        <li><Link to="/Education">Education</Link></li>
                        <li><Link to="/Awards">Honor & Awards</Link></li>
                    </ul>
                </li>
                <li><Link>Publications</Link>
                    <ul className="sub-menu">
                        <li><Link to="/Journals">Journals</Link></li>
                        <li><Link to="/Conferences">Conferences</Link></li>
                        <li><Link to="/Books">Books</Link></li>
                        {/*<li><Link to="/publications/multimedia">Multimedia</Link></li>*/}
                    </ul>
                </li>
                {/*<li><Link to="/projects">Projects</Link>*/}
                {/*    <ul className="sub-menu">*/}
                {/*        <li><Link to="/projects/ongoing">Ongoing Projects</Link></li>*/}
                {/*        <li><Link to="/projects/completed">Completed Projects</Link></li>*/}
                {/*    </ul>*/}
                {/*</li>*/}
                <li><Link>Positions</Link>
                    <ul className="sub-menu">
                        <li><Link to="/academic">Academic</Link></li>
                        <li><Link to="/journalposition">Journal</Link></li>
                        <li><Link to="/executive">Executive</Link></li>
                        {/*<li><Link to="/publications/multimedia">Multimedia</Link></li>*/}
                    </ul>
                </li>
                <li><Link>Skills</Link>
                    <ul className="sub-menu">
                        <li><Link to="/teaching">Teaching</Link></li>
                        <li><Link to="/otherskills">Other Skills</Link></li>
                    </ul>
                </li>
                <li><Link>People</Link>
                    <ul className="sub-menu">
                        <li><Link to="/collaboration">Research Collaboration</Link></li>
                        <li><Link to="/phdstudents">PhD Students</Link></li>
                        <li><Link to="/masterstudents">Master Students</Link></li>
                    </ul>
                </li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
            {/*</nav>*/}
        </div>
    );
};

export default Menu;
