import React from 'react';

const ProfessorCard = ({ name, project, year, image, onClick }) => {
    return (
        <div className="professor-card" onClick={onClick}>
            <img
                style={{ width: '250px',
                height: '250px'}}
                src={image} alt={name}/>
            <div className="card-details">
                <h3>{name}</h3><br></br>
                <p>{project}</p>
                <p>{`Year(s) of Collaboration:`}</p>
                <p>{`${year}`}</p>
            </div>
        </div>
    );
};

export default ProfessorCard;