import React from 'react';
import myImage from '../static/images/personal_img.jpg';
import '../styles/Home.css'

const Home = () => {
    return (

        <div style={{textAlign: 'center'}}>
            <h2>Welcome </h2>
            <div>
                <img style={{borderRadius: '50%'}} src={myImage} alt="A portrait of Majid Amirfakhrian" width="418"
                     height="398"/>
            </div>

            {/*<p>This is the home page of my website.</p>*/}
            <h2>Full Professor of Applied Mathematics</h2>
            <h4>and</h4>
            <h2 className='type1'>Data Scientists</h2>
        </div>

    );
};

export default Home;


