// src/components/About.js
import React from 'react';

const About = () => {
    return (
        <div  className="body2">
            <h1>
                About Me
            </h1>
            Welcome to my corner of the digital world! I'm a results-driven Data Scientist and Software Developer with a passion for unraveling complex data puzzles and transforming them into actionable insights. With over a decade of professional experience, I specialize in Python, R, SQL, and data visualization tools like Power BI. My expertise encompasses machine learning, statistical analysis, and mathematical modeling.

            <h2>
                Unveiling Data's Hidden Secrets
            </h2>
            I believe in the power of data, and I'm on a relentless quest to extract its hidden secrets. Whether it's predicting future trends, optimizing processes, or aiding in decision-making, data is at the core of everything I do. My journey into the world of data began with a strong foundation in mathematics, which has led to the publication of over 100 scientific papers in renowned journals.

            <h2>
                A Diverse Range of Interests
            </h2>

            My curiosity knows no bounds. I've ventured into diverse research areas, from Data Sciences and Image Processing to Numerical Weather Prediction and Digital Farming. This multidisciplinary approach allows me to draw connections and insights from various fields, bringing a fresh perspective to every project.

            <h2>
                The Story of My Career
            </h2>

            <h3>
                Academic Roots
            </h3>
            My career kicked off in academia. I was a Professor of Applied Mathematics, teaching and nurturing the mathematical skills of future generations. The academic environment instilled a passion for research and a dedication to furthering human knowledge. During this time, I also had the privilege of supervising and mentoring students and guiding them in their academic and research pursuits.

            <h3>
                Data Science and Agriculture
            </h3>
            My journey led me to the University of Calgary, where I wear the hat of a Research Assistant. Here, I've been at the forefront of Agriculture Application research. I collaborate with multidisciplinary teams, extracting valuable insights from satellite images. With the aid of Pandas, Scikit-Learn, and the Wolfram Language, I ensure data integrity and craft dynamic visual representations. Statistical analysis and predictive modeling are my trusted companions, driving tangible enhancements in operational efficiency.

            I'm proud to have pioneered innovative methodologies for estimating remote sensing indices across vast geographical areas, ushering in a new era of data accuracy in agriculture. The importance of data-driven methodologies in agriculture has never been clearer.

            <h3>
                Data Science and Weather
            </h3>
            In a previous role at the same institution, I dove into Weather Applications. I extracted and refined meteorological data, conducted data analysis, and implemented algorithms for weather-related research. The goal? To improve processes and operational effectiveness while contributing to weather data publications. I'm always fascinated by the impact of weather on our daily lives and the significance of accurate predictions.

            <h3>
                Data Science and Image Analysis
            </h3>
            My expertise isn't limited to academia or agriculture; I've taken on challenges in the real world as well. At Azad University, in collaboration with Insurance Co., I led the charge in the development of a comprehensive mathematical model for vehicle damage detection. I used MATLAB and Python to implement this innovative approach, employing advanced image processing techniques for precise damage assessment. My work with Convolutional Neural Networks (CNN) and other cutting-edge technologies pushed the boundaries of image analysis.

            <h3>
                A Lifelong Learner
            </h3>
            My commitment to learning is unwavering. I hold a Diploma in Software Development and continually expand my knowledge. Certifications in Power BI, Python, Java, and other technologies reflect my dedication to staying current in a rapidly evolving field.

            <h3>
                Beyond the Data
            </h3>
            While I'm deeply immersed in the world of data, I'm not all 1s and 0s. I appreciate the outdoors, especially on the rugged landscapes of Calgary. Nature's beauty reminds me of the complexity and wonder of the world, similar to the data I deal with daily. It's a reminder to stay humble and curious.

            Thank you for visiting my website and taking a glimpse into my world.
        </div>
    );
};

export default About;
