import React, {useState, useEffect} from 'react';
import axios from 'axios';
import FlashCardPositionJ from "./FlashCardPosition_J";

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error); // Propagate the error
    }
);

const fetchPositions = async () => {
    try {
        const response = await axios.get('positions/jsons/journal_positions.json');
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching Positions data. HTTP status:', error.response?.status);
        return [];
    }
};


const AcademicPositions = () => {
    const [positions, setPositions] = useState([]);
    const [clickedIndex, setClickedIndex] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const positionsData = await fetchPositions();
            setPositions(positionsData);
        };

        fetchData();
    }, []);


    return (
        <div  className="body2">
            <h1>Position in Some Journals</h1>
            {positions.map((position, index) => (
                <div key={index} className="position">
                    {/*<fieldset>*/}
                    {/*    <legend>Academic Position</legend>*/}
                        <a className="publication-details"
                           href={position.link}>
                            <div>
                                <FlashCardPositionJ
                                    key={index} {...position} />
                            </div>
                        </a>

                    {/*</fieldset>*/}


                </div>
            ))}
        </div>


    );
};

export default AcademicPositions;

