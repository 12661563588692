// import React, { useEffect, useState } from 'react';

const Teaching = () => {
    return (
        <div className="body2">
            <h1>Teaching Experience</h1>
            <h2>
                Data Analytics
            </h2>
            <ul>
                <li>Data Literacy</li>
                <li>Business Context for Data Analysis</li>
                <li>Statistical Analysis of Data</li>
                <li>Predictive Analytics</li>
                <li>Business Analytics with Excel</li>
                <li>Business Intelligence Reporting</li>
                <li>Advanced Concepts in Data Analytics</li>
            </ul>
            <h2>
                Applied Mathematics and Engineering
            </h2>
            <h3>
                Post-Graduate Courses (PhD and Master)
            </h3>
            <ul>
                <li>Advanced Approximation Theory</li>
                <li>Approximation of Fuzzy Functions</li>
                <li>Advanced Numerical Differential Equation</li>
                <li>Radial Basis Functions</li>
                <li>Generalized Analytical Functions</li>
                <li>Calculus of Variation</li>
                <li>Special Functions</li>
                <li>Advanced Image Processing</li>
                <li>Approximation Theory</li>
                <li>Advanced Numerical Analysis</li>
                <li>Numerical Methods for Integral Equations</li>
                <li>Numerical Linear Algebra</li>
                <li>Numerical Software</li>
                <li>Research Methodology</li>
                <li>Image Processing</li>
            </ul>
            <h3>
                Graduate Courses
            </h3>
            <ul>
                <li>Numerical Analysis I</li>
                <li>Numerical Analysis II</li>
                <li>Calculus I</li>
                <li>Calculus II</li>
                <li>Calculus III</li>
                <li>Numerical Computations</li>
                <li>Linear Algebra</li>
                <li>Operations Research I</li>
                <li>Operations Research II</li>
                <li>Pre-Calculus</li>
                <li>Computer Programming</li>
                <li>Advanced Computer Programming</li>
                <li>Discrete Mathematics</li>
                <li>Differential Equations</li>
                <li>Engineering Mathematics</li>
                <li>Fuzzy Mathematics</li>
            </ul>
        </div>

    );
};

export default Teaching;

