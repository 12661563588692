
import React from "react";

const Skills = () => {
      return (
                <div>

                        <h1>Skills</h1>
                        <div className="publication">
                                <a href="/teaching">
                                        <fieldset>
                                                <legend>Teaching</legend>
                                                Teaching Experience
                                        </fieldset>
                                </a>
                        </div>
                        <div className="publication">
                                <a href="/otherSkills">
                                        <fieldset>
                                                <legend>Other Skills</legend>
                                            Other Skills
                                        </fieldset>
                                </a>
                        </div>
                </div>

    );
};

export default Skills;

