import React from 'react';


const MasterStudCard = ({ student_name, subject, year, image }) => {

    return (
        <div className="ms-student-card">
            <img src={image} alt={student_name} />
            <div className="card-details">
                <h3>{student_name}</h3>
                <br></br>
                <p>{`Research Area:`}</p>
                <p>{subject}</p>
                <p>{`Year of Completion: ${year}`}</p>
            </div>
        </div>
    );
};

export default MasterStudCard;
