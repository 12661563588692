import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PhDStudCard from '../Elements/people/PhDStudCard';
import '../../styles/People.css';

const PhdStudents = () => {
    const [phdstudents, setPhdStuds] = useState([]);

    useEffect(() => {
        const fetchPhDs = async () => {
            try {
                const response = await axios.get('/people/jsons/phd_students.json');
                setPhdStuds(response.data);
            } catch (error) {
                console.error('Error fetching professors data:', error);
            }
        };

        fetchPhDs();
    }, []);

    return (
        <div  className="body3">
            <h1>My Former PhD Students</h1>
            <div className="student-container">
                {phdstudents.map((student, index) => (
                    <PhDStudCard key={index} {...student} />
                ))}
            </div>
            <div>
                <a href="https://www.flaticon.com/free-icon/man_3001758?term=man&page=1&position=34&origin=search&related_id=3001758">
                    <small> <font color="red">Man icon </font>created by Freepik - Flaticon.</small>
                </a>
                <br></br>
                <a href="https://www.flaticon.com/free-icon/woman_3278793?term=woman&page=1&position=7&origin=search&related_id=3278793">
                    <small> <font color="red">Woman icon </font>created by Freepik - Flaticon.</small>
                </a>
            </div>
        </div>

    );
};

export default PhdStudents;