// src/components/Header.js
import React from 'react';
import '../../../styles/Header.css';
const Header = () => {
    return (
        <header>
            <h1>Majid Amirfakhrian</h1>
        </header>
    );
};

export default Header;
