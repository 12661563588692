// src/components/Contact.js
import React from 'react';

const Contact = () => {
    return (
        <div  className="body2">
            <h2>Contact Me</h2>
            {/*<p>Feel free to reach out to me using the information below:</p>*/}

            <ul>
                <li>Email: majid.amirfakhrian [at] ucalgary.ca</li>
                {/*<li>Phone: (123) 456-7890</li>*/}
                <li>LinkedIn: <a href="https://www.linkedin.com/in/amirfakhrian/" target="_blank" rel="noopener noreferrer">My LinkedIn Profile</a></li>
                <li>Google Scholar: <a href="https://scholar.google.com/citations?hl=en&user=pxYJdqsAAAAJ&view_op=list_works&gmla=AJsN-F6_r56BuNt5m87vTxXRck7W1zC0NStfQkfzPEAONX3hj8u8q9HsDR8p6D50JWhi1rqUboe2AekFQDy_hX-xs471Pusd8bpdWd6FPbUXAWe3OAojh_6MqixrSpTmguj2rOvLNfhZ" target="_blank" rel="noopener noreferrer">My Google Scholar Profile</a></li>
            </ul>
        </div>
    );
};

export default Contact;
